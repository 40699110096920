import { SpeechMark } from "../typings";

import { expandRangeAroundNode } from "./expandRangeAroundNode";
import { extractFirstWordInRange } from "./extractFirstWordInRange";
import { getActiveWordIndex } from "./getActiveWordIndex";
import { isValidWord } from "./isValidWord";

type Args = {
  range: Range;
  progressInMs: number;
  speechMarks: SpeechMark[];
  prevIndex?: number;
};

let hasInsertedStylesheet = false;

/**
 * Returns a new word node in case the karaoke has moved to a new word.
 *
 * NB: This method manipulates the passed range in order to optimize subsequent
 * calls. Therefore, in case audio is seeked or similar during karaoke, the range
 * must be reset to the original range.
 */
export function getNewWordRange({
  progressInMs,
  speechMarks,
  prevIndex = -1,
  range,
}: Args):
  | {
      index: number;
      range: Range;
      highlightNode: HTMLSpanElement;
    }
  | undefined {
  injectHighlightNodeStylesheet();

  let currentWordIndex = getActiveWordIndex(speechMarks, progressInMs);
  let currentWordRange: Range | undefined;

  // In case we've moved to a new word since last checked, then attempt to extract
  // the new range...
  if (currentWordIndex !== undefined && currentWordIndex > prevIndex) {
    // ... by first moving our range to hopefully be right before the desired word
    if (currentWordIndex > 0) {
      for (let i = prevIndex; i < currentWordIndex; i++) {
        moveRangeToNextWord(range);
      }
    }

    currentWordRange = extractFirstWordInRange(range);

    // ... however, if the obfuscating HTML was found in the range, then skip it
    // until the currentWordRange contains the desired word
    while (currentWordRange && !isValidWord(currentWordRange.toString())) {
      range.setStart(currentWordRange.endContainer, currentWordRange.endOffset);

      const nextWordRange = extractFirstWordInRange(range);

      // In case we cannot move the range anymore, then break out!
      if (!nextWordRange) {
        break;
      }

      currentWordIndex++;
      currentWordRange = nextWordRange;
    }

    // In case we successfully found a new word range, then insert our highlight
    // wrapper around it and return
    if (currentWordRange) {
      const highlightNode = document.createElement("span");
      highlightNode.dataset.gPolly = "true";

      // Ensure we inclde all relevant HTMLElements around our word
      expandRangeAroundNode(currentWordRange);

      // ... and then remove it from the DOM to insert it into our highlightnode
      highlightNode.appendChild(currentWordRange.extractContents());
      currentWordRange.insertNode(highlightNode);

      return {
        range: currentWordRange,
        index: currentWordIndex,
        highlightNode,
      };
    }
  }
}

/**.
 *
 * Moves a range to the next valid word in the range. In case this operation
 * couldn't be successfully completed, then false is returned.
 */
function moveRangeToNextWord(range: Range): boolean {
  const nextRange = extractFirstWordInRange(range);

  // Push the remaining range forwards, slicying away the first
  // word within it
  if (nextRange !== undefined) {
    range.setStart(nextRange.endContainer, nextRange.endOffset);

    return true;
  }

  return false;
}

function injectHighlightNodeStylesheet() {
  if (!hasInsertedStylesheet) {
    const css = `
      [data-g-polly="true"]::before,
      [data-g-polly="true"]::after {
        display: none !important;
      }
    `;
    const style = document.createElement("style");

    style.setAttribute("data-g-polly", "true");
    style.appendChild(document.createTextNode(css));

    (document.head ?? document.body).appendChild(style);
  }

  hasInsertedStylesheet = true;
}
