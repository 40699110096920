import { isTextNode } from "./isNode";

/**
 * When a range has been constructed around a word, then it will not necessarily
 * contain HTMLElements that makes up the given word, hence we expand our range
 * until required nodes have been added.
 */
export function expandRangeAroundNode(range: Range): void {
  const exitCondition = range.commonAncestorContainer;

  // As long as we can safely include more parent HTMLElements before the word, then
  // do so here.
  while (
    range.startOffset === 0 &&
    range.startContainer.parentNode !== null &&
    range.startContainer !== exitCondition
  ) {
    range.setStartBefore(range.startContainer);
  }

  // As long as we can safely include more parent HTMLElements after the word, then
  // do so here.
  while (
    range.endOffset === getRangeElementLength(range.endContainer) &&
    range.endContainer.parentNode !== null &&
    range.endContainer !== exitCondition
  ) {
    range.setEndAfter(range.endContainer);
  }
}

function getRangeElementLength(element: Node | Text): number {
  return isTextNode(element)
    ? getRangeTextLength(element)
    : getRangeNodeLength(element);
}

function getRangeNodeLength(node: Node): number {
  return node.childNodes.length;
}

function getRangeTextLength(text: Text): number {
  return text.length;
}
