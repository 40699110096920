import { latexParser } from "latex-utensils";

import { printLatexNodes } from "./print-latex-nodes";
import { printLatexNodeMathematically } from "./printers/mathematical/_print-latex-node";

export type LatexPrinter = "mathematical";

export function translateLatexFormulaToText(
  latexStr: string,
  printer: LatexPrinter
): string {
  const root = latexParser.parse(latexStr);
  const math = root.content[0];

  if (math?.kind !== "inlineMath" || root.content.length !== 1) {
    // give up if a single math formula wasn't given
    return latexStr;
  }

  const print = (() => {
    switch (printer) {
      case "mathematical":
        return printLatexNodeMathematically;

      default:
        throw new Error("translateLatexFormulaToText(): unknown printer");
    }
  })();

  return printLatexNodes(print, math.content);
}
