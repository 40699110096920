import { SpeechSpeeds, SpeechVoices } from "./constants";
import {
  readOutLoud,
  synthesizeSpeech,
  synthesizeLongSpeech,
  registerRequestInterceptor,
} from "./methods";
import { translateLatexFormulaToText } from "./utils/latex/translate-latex-formula";

/**
 * Exposes all publicly available methods from the gPolly namespace under a
 * single module.
 */
export type gPolly = {
  synthesizeSpeech: typeof synthesizeSpeech;
  synthesizeLongSpeech: typeof synthesizeLongSpeech;
  readOutLoud: typeof readOutLoud;
  registerRequestInterceptor: typeof registerRequestInterceptor;
  translateLatexFormulaToText: typeof translateLatexFormulaToText;
  Voices: typeof SpeechVoices;
  Speeds: typeof SpeechSpeeds;
};

declare global {
  interface Window {
    gPolly?: gPolly;
  }
}

window.gPolly = {
  readOutLoud,
  synthesizeSpeech,
  synthesizeLongSpeech,
  registerRequestInterceptor,
  translateLatexFormulaToText,
  Voices: SpeechVoices,
  Speeds: SpeechSpeeds,
};
