import type { latexParser } from "latex-utensils";

import { makeOutputFragment } from "../../make-output-fragment";
import { PrintingContext, PrintingOutput } from "../../print-latex-nodes";

export function handleTextString(
  ctx: PrintingContext,
  node: latexParser.TextString
): PrintingOutput {
  const nextContext: PrintingContext = { ...ctx, prevNode: "string" };

  return [makeOutputFragment(node.content), nextContext];
}
