import { latexParser } from "latex-utensils";

import { PrintingContext, PrintingOutput } from "../../print-latex-nodes";

import { handleArgGroup } from "./arg.group";
import { handleCommand } from "./command";
import { handleTextCommand } from "./command.text";
import { handleMathCharacter } from "./math.character";
import { handleMathDelimiters } from "./math.math_delimiters";
import { handleSuperscript } from "./superscript";
import { handleTextString } from "./text.string";
import { handleWhitespace } from "./whitespace";

export function printLatexNodeMathematically(
  context: PrintingContext,
  node: latexParser.Node
): PrintingOutput {
  switch (node.kind) {
    case "arg.group":
    case "arg.optional":
      return handleArgGroup(printLatexNodeMathematically, context, node);

    case "command":
      return handleCommand(printLatexNodeMathematically, context, node);

    case "math.character":
      return handleMathCharacter(context, node);

    case "math.math_delimiters":
      return handleMathDelimiters(printLatexNodeMathematically, context, node);

    case "superscript":
      return handleSuperscript(printLatexNodeMathematically, context, node);

    case "text.string":
      return handleTextString(context, node);

    case "command.text":
      return handleTextCommand(printLatexNodeMathematically, context, node);

    case "linebreak":
    case "space":
      return handleWhitespace(context);

    default:
      console.warn(
        `mapNodeToHandler(${node.kind}): No handler associated to type.`,
        node
      );

      // In case the node could not be handled, fall back to simply parsing to
      // an empty string
      return ["", context];
  }
}
