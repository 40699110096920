import { isIrrelevantNode } from "./isIrrelevantNode";
import { isElementNode } from "./isNode";

/**
 * Browsers have this horrible horrible bug, where they will not report line
 * breaks as being part of a range.toString() call...
 *
 * We're going to have to circumvent this by resorting to the fact that cloning
 * the range contents as a DocumentFragment, mounting that fragment into a
 * temporary node and then reading .innerText works well...
 */
export function getTextFromRange(range: Range): string {
  // Create a temporary, detached node
  const temporaryNode = document.createElement("div");

  // Make sure that the temporary node will not interfere with the flow of the
  // document.
  // For Safari (14) it is very important that the size of the temporary node
  // is the same as the actual node, since a small node may interfere with how
  // Safari parses node.innerText.
  //
  // E.g. two text nodes next to each other ("Læs", "mere") in a small container
  // where they collapse into two lines will be parsed as "Læsmere" instead of
  // "Læs mere" in Safari when using node.innerText.
  temporaryNode.style.cssText = `
      position: absolute;
      top: -100vw;
      left: -100vw;
      width: 100%;
      opacity: 0.001;
  `;

  // Mount the cloned range contents into the temporary node
  temporaryNode.appendChild(range.cloneContents());

  // Remove <script /> tags from the temporary node, as these may cause issues
  // with the way that text content is being extracted by the browser...
  for (const scriptNode of Array.from(
    temporaryNode.getElementsByTagName("script")
  )) {
    if (scriptNode.parentNode != null) {
      scriptNode.parentNode.removeChild(scriptNode);
    }
  }

  // Now that we've cleaned up the content of the text range, mount the
  // temporary node into the DOM
  document.body.appendChild(temporaryNode);

  // Iterate through all children within the temporary node, and make sure
  // that everything that's tagged with user-select: none is going to be
  // ignored...
  recursivelyFindIrrelevantChildren(temporaryNode).forEach((child) => {
    child.remove();
  });

  // Extract text content from the node
  const text = temporaryNode.innerText;

  // Remove the temporary node instantly from the DOM again
  document.body.removeChild(temporaryNode);

  return text;
}

function recursivelyFindIrrelevantChildren(node: Element | Text): ChildNode[] {
  let result: ChildNode[] = [];

  for (const child of Array.from(node.childNodes)) {
    if (isIrrelevantNode(child)) {
      result.push(child);
    } else if (isElementNode(child)) {
      result = [...result, ...recursivelyFindIrrelevantChildren(child)];
    }
  }

  return result;
}
