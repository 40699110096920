import type { latexParser } from "latex-utensils";

import { makeOutputFragment } from "../../make-output-fragment";
import {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
  printLatexNodes,
} from "../../print-latex-nodes";

export function handleMathDelimiters(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.MathDelimiters
): PrintingOutput {
  const nextCtx: PrintingContext = { ...ctx, prevNode: "other" };

  if (node.left === "(" && node.right === ")") {
    if (ctx.prevNode === "string") {
      return [
        makeOutputFragment(`af ${printLatexNodes(printer, node.content)}`),
        nextCtx,
      ];
    } else {
      return [
        makeOutputFragment(
          `parentes start ${printLatexNodes(
            printer,
            node.content
          )} parentes slut`
        ),
        nextCtx,
      ];
    }
  }

  return ["", nextCtx];
}
