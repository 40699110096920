import { SpeechSpeeds, SpeechVoices } from "../constants";
import { getBaseUrl } from "../utils";

let requestInterceptor: (
  url: string,
  init: RequestInit
) => Promise<Request> | Request = async (url, init) => {
  if (typeof window.gProxy === "undefined") {
    return new Request(url, init);
  }

  return new Request(
    `${window.gProxy.getBackendUrl()}proxy/${encodeURIComponent(
      url
    )}?noCache=${Date.now()}`,
    {
      ...init,
      headers: {
        ...init.headers,
        "X-Security-Method": "amazon.export.gyldendal.dk",
        "X-Access-Token": await window.gProxy.getJWT(),
      },
    }
  );
};

/**
 * perform request to trigger the speech synthesis task executor and provide the
 * response to frontend integrations.
 *
 * NOTE that this method will connect directly to AWS Polly and is as such
 * subject to the same limitations provided by that service, for instance
 * 3.000 characters.
 */
export async function synthesizeSpeech(
  args: SpeechSynthesisArgs & { includeSpeechMarks: true }
): Promise<
  SpeechSynthesisResponse &
    Required<Pick<SpeechSynthesisResponse, "speechMarks">>
>;
export async function synthesizeSpeech(
  args: SpeechSynthesisArgs & { includeSpeechMarks: false | undefined }
): Promise<Omit<SpeechSynthesisResponse, "speechMarks">>;
export async function synthesizeSpeech(
  args: Omit<SpeechSynthesisArgs, "includeSpeechMarks">
): Promise<Omit<SpeechSynthesisResponse, "speechMarks">>;
export async function synthesizeSpeech(
  args: SpeechSynthesisArgs
): Promise<SpeechSynthesisResponse>;
export async function synthesizeSpeech(
  args: SpeechSynthesisArgs
): Promise<SpeechSynthesisResponse> {
  const response = await fetch(
    await requestInterceptor(`${getBaseUrl()}/synthesizeSpeech`, {
      method: "POST",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
  );

  return response.json();
}

/**
 * utility to replace the default request interceptor, which will allow
 * integrations to perform basic request re-routing, authorization etc.
 */
export function registerRequestInterceptor(
  nextRequestInterceptor: (
    url: string,
    init: RequestInit
  ) => Promise<Request> | Request
): void {
  requestInterceptor = nextRequestInterceptor;
}

export type SpeechSynthesisArgs = {
  text: string;
  voice?: SpeechVoices;
  speed?: SpeechSpeeds;
  includeSpeechMarks?: boolean;
  cacheForMs?: number;
};

export type SpeechSynthesisResponse = {
  mp3Url: string;
  speechMarks?: {
    /**
     * the time (in milliseconds) from the start of the mp3 file till this
     * word starts being read out.
     */
    time: number;

    /**
     * the text content of the word that's being read out loud.
     */
    value: string;
  }[];
};

synthesizeSpeech.Speeds = SpeechSpeeds;
synthesizeSpeech.Voices = SpeechVoices;
