import { SpeechVoices } from "../../constants";
import { SpeechSynthesisArgs } from "../../methods";
import { splitTextIntoPollyChunks } from "../../utils";
import {
  LatexPrinter,
  translateLatexFormulaToText,
} from "../../utils/latex/translate-latex-formula";

import { getTextFromRange } from "./getTextFromRange";
import { isIrrelevantNode } from "./isIrrelevantNode";
import { isElementNode } from "./isNode";

export type RangeType = "text" | "custom";

export type RangeChunk = {
  /**
   * contains a range representing the exact part of the text, that's currently
   * being read out loud; this is very useful by external integrations, e.g. to
   * perform karaoke highlighting of internal nodes
   */
  range: Range;

  /**
   * specifies the type of the chunk, so that it can be correctly processed by
   * the speaker
   */
  type: RangeType;

  /**
   * contains the extracted content, exactly as it should be read out loud (e.g.
   * a string that's ready to be submitted to AWS Polly for speech synthesis)
   */
  content: string[];

  /**
   * if required, args can optionally be overwritten by chunk splitting (this
   * allows us to seamlessly change configurations, e.g. if LaTeX needs to be
   * read out loud slower than other text, or with another voice for a clearer
   * expression of the intent).
   */
  args?: Partial<SpeechSynthesisArgs>;
};

type Options = {
  voice: SpeechVoices;
  latexPrinter: LatexPrinter | undefined;
};

export function splitRangeIntoChunks(
  range: Range,
  options: Options
): RangeChunk[] {
  const result: RangeChunk[] = [];

  // iterate over every single node contained within the selection, and process
  // each of them individually
  let remainingRange = range.cloneRange();

  function processNode(node: Element) {
    if (
      !range.intersectsNode(node) ||
      isIrrelevantNode(node, { allowLatex: true })
    ) {
      return;
    }

    const processResult =
      processKaTeXNode(remainingRange, node, options) ??
      processMathJaxNode(remainingRange, node, options) ??
      processGeogebraNode(remainingRange, node, options);

    if (processResult) {
      // if we found a match, then start from injecting a plain-text chunk that
      // runs from the start of the remaining range until just before the
      // processed node
      const priorRange = remainingRange.cloneRange();
      priorRange.setEndBefore(node);

      result.push(createTextRangeChunk(priorRange));

      // ... and then push the chunk resulting from the processor itself, and
      // update the remaining range
      result.push(processResult.chunk);
      remainingRange = processResult.remainingRange;
    }

    Array.from(node.children).forEach((child) => processNode(child));
  }

  const rootNodes = Array.from(
    range.commonAncestorContainer.parentElement?.children ?? []
  );

  rootNodes.forEach((node) => processNode(node));

  // once we get here, push a final text range that contains the remaining text
  // content from the remaining range
  result.push(createTextRangeChunk(remainingRange));

  return result.filter((it) => it.content.length > 0);
}

/**
 * processors when splitting a range into chunks will be given a single DOM
 * node, and can then optionally provide required manipulations in order for it
 * to be correctly read out loud - e.g. MathJax can be transformed into a
 * "custom" chunk, which will then highlight the entire range within the DOM
 * when using karaoke
 *
 * further the processor should return the remaining range that should be
 * processed for subsequent node processing
 *
 * if the processor doesn't match a given node, or it isn't able to perform the
 * required processing, it can bail out by returning undefined - in which case
 * our chunk splitter will continue to the next defined processor
 */
type NodeProcessResult =
  | { chunk: RangeChunk; remainingRange: Range }
  | undefined;

/**
 * @todo once mathjax has been fully obsoleted this code should be removed.
 */
function processMathJaxNode(
  range: Range,
  node: Element,
  options: Options
): NodeProcessResult {
  if (!options.latexPrinter || !node.matches(`.MathJax`)) {
    return;
  }

  // if we've found a rendered formula inserted by MathJax, then we first need
  // to find the LaTeX definition for the rendered content so that we can
  // translate it into
  const latexNode = node.nextSibling;

  if (
    !latexNode ||
    !isElementNode(latexNode) ||
    !latexNode.matches(`script[type="math/tex"]`)
  ) {
    // if we were unable to find the LaTeX definition, we cannot process the
    // rendered output correctly - bail out
    return;
  }

  // create a chunk that contains the desired text output
  const chunk: RangeChunk = {
    type: "custom",
    range: createRangeFromNode(node),

    // translate the LaTeX into readable text, by processing the actual LaTeX
    // definition
    content: splitTextIntoPollyChunks(
      translateLatexFormulaToText(
        `$${latexNode.textContent}$`,
        options.latexPrinter
      )
    ),

    // enforce reading LaTeX formula with a danish voice, as that is the only
    // translator that currently exists
    args:
      options.voice !== SpeechVoices.DANISH_FEMALE &&
      options.voice !== SpeechVoices.DANISH_MALE
        ? {
            voice: SpeechVoices.DANISH_MALE,
          }
        : undefined,
  };

  // ... and create a remaining range that continues after the end of the
  // MathJax definition
  const remainingRange = range.cloneRange();
  remainingRange.setStartAfter(latexNode);

  return { remainingRange, chunk };
}

function processKaTeXNode(
  range: Range,
  node: Element,
  options: Options
): NodeProcessResult {
  if (!options.latexPrinter || !node.matches(`.katex`)) {
    return;
  }

  // if we've found a rendered formula inserted by MathJax, then we first need
  // to find the LaTeX definition for the rendered content so that we can
  // translate it into
  const latexNode = node.querySelector(
    "annotation[encoding='application/x-tex']"
  );

  if (!latexNode || !isElementNode(latexNode)) {
    // if we were unable to find the LaTeX definition, we cannot process the
    // rendered output correctly - bail out
    return;
  }

  // create a chunk that contains the desired text output
  const chunk: RangeChunk = {
    type: "custom",
    range: createRangeFromNode(node.querySelector("katex-html") ?? node),

    // translate the LaTeX into readable text, by processing the actual LaTeX
    // definition
    content: splitTextIntoPollyChunks(
      translateLatexFormulaToText(
        `$${latexNode.textContent}$`,
        options.latexPrinter
      )
    ),

    // enforce reading LaTeX formula with a danish voice, as that is the only
    // translator that currently exists
    args:
      options.voice !== SpeechVoices.DANISH_FEMALE &&
      options.voice !== SpeechVoices.DANISH_MALE
        ? {
            voice: SpeechVoices.DANISH_MALE,
          }
        : undefined,
  };

  // ... and create a remaining range that continues after the end of the
  // KaTex definition
  const remainingRange = range.cloneRange();
  remainingRange.setStartAfter(node);

  return { remainingRange, chunk };
}

function processGeogebraNode(
  range: Range,
  node: Element,
  options: Options
): NodeProcessResult {
  if (!options.latexPrinter || !node.matches(`img[data-latex]`)) {
    return;
  }

  // create a chunk that contains the desired text output
  const chunk: RangeChunk = {
    type: "custom",
    range: createRangeFromNode(node),

    // translate the LaTeX into readable text, by processing the actual LaTeX
    // definition
    content: splitTextIntoPollyChunks(
      translateLatexFormulaToText(
        `$${node.getAttribute("data-latex")}$`,
        options.latexPrinter
      )
    ),

    // enforce reading LaTeX formula with a danish voice, as that is the only
    // translator that currently exists
    args:
      options.voice !== SpeechVoices.DANISH_FEMALE &&
      options.voice !== SpeechVoices.DANISH_MALE
        ? {
            voice: SpeechVoices.DANISH_MALE,
          }
        : undefined,
  };

  // ... and create a remaining range that continues after the end of the
  // Geogebra definition
  const remainingRange = range.cloneRange();
  remainingRange.setStartAfter(node);

  return { remainingRange, chunk };
}

/**
 * utility to create a RangeChunk definition with the extracted text of any
 * arbitrary range
 */
function createTextRangeChunk(range: Range): RangeChunk {
  return {
    type: "text",
    range: range,
    content: splitTextIntoPollyChunks(
      getTextFromRange(range)
        .replace(/\r|\t/g, "\n")
        .replace(/\s+\n/g, "\n")
        .replace(/([^.:!?0-9])\n+/g, "$1. ")
        .replace(/\s+/g, " ")
        .trim()
    ),
  };
}

/**
 * utility to create a range containin a single node
 */
function createRangeFromNode(node: Node): Range {
  const range = document.createRange();
  range.selectNode(node);

  return range;
}
