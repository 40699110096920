import type { latexParser } from "latex-utensils";

import {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
  printLatexNodes,
} from "../../print-latex-nodes";

export function handleArgGroup(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.Group | latexParser.OptionalArg
): PrintingOutput {
  return [
    printLatexNodes(printer, node.content, {
      trimStart: false,
    }),
    ctx,
  ];
}
