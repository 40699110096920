import type { latexParser } from "latex-utensils";

import { isInteger } from "../../helpers/is-integer";
import { makeOutputFragment } from "../../make-output-fragment";
import {
  NodePrinter,
  PrintingContext,
  PrintingOutput,
  printLatexNodes,
} from "../../print-latex-nodes";

export function handleCommand(
  printer: NodePrinter,
  ctx: PrintingContext,
  node: latexParser.Command
): PrintingOutput {
  const nextCtx: PrintingContext = { ...ctx, prevNode: "other" };

  switch (node.name) {
    case "cdot":
    case "times":
      return [makeOutputFragment("gange"), nextCtx];

    case "div":
      return [makeOutputFragment("divideret med"), nextCtx];

    case "%":
      return [makeOutputFragment("procent"), nextCtx];

    case "pi":
      return [makeOutputFragment("pi"), nextCtx];

    case "approx":
      return [makeOutputFragment("cirka li med"), nextCtx];

    case "frac": {
      const numerator = node.args[0];
      const denominator = node.args[1];

      if (!numerator || !denominator) {
        console.warn(
          "translateLatexFormula(): unsupported fraction, exactly two arguments needed %0",
          it
        );

        return ["", nextCtx];
      }

      let numeratorOutput = printLatexNodes(printer, numerator);
      let denominatorOutput = printLatexNodes(printer, denominator);

      // special case for whole number fractions to be properly translated into
      // danish "brøker"
      if (isInteger(numeratorOutput) && isInteger(denominatorOutput)) {
        switch (denominatorOutput) {
          case "1":
            denominatorOutput = numeratorOutput === "1" ? "hel" : "hele";
            break;

          case "2":
            denominatorOutput = numeratorOutput === "1" ? "halv" : "halve";
            break;

          default:
            denominatorOutput =
              numeratorOutput === "1"
                ? `${denominatorOutput}. del`
                : `${denominatorOutput}. dele`;
            break;
        }

        numeratorOutput = numeratorOutput === "1" ? "en" : numeratorOutput;

        return [
          makeOutputFragment(`${numeratorOutput} ${denominatorOutput}`),
          nextCtx,
        ];
      }

      // everything is simply read as a division
      return [
        makeOutputFragment(`${numeratorOutput} over ${denominatorOutput}`),
        nextCtx,
      ];
    }

    case "sqrt": {
      const sqrt = node.args.find((it) => it.kind === "arg.group");
      const numerator = node.args.find((it) => it.kind === "arg.optional");

      if (!sqrt) {
        return ["", nextCtx];
      }

      const numeratorOutput = numerator
        ? makeOutputFragment(printLatexNodes(printer, numerator))
        : "";
      const sqrtOutput = makeOutputFragment(
        `kvadratroden af ${printLatexNodes(printer, sqrt)}`,
        {
          spaceBefore: !!numeratorOutput,
        }
      );

      return [makeOutputFragment(`${numeratorOutput}${sqrtOutput}`), nextCtx];
    }

    default:
      console.warn("handleCommand(): unknown command %o", node);
  }

  return ["", nextCtx];
}
