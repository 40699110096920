import { SpeechMark } from "../typings";

/**
 * Returns the index of the SpeechMark that should currently be highlighted based
 * on the current progress.
 *
 * This implementation is based on a binary sort since SpeechMarks comes sorted
 * by timestamp.
 */
export function getActiveWordIndex(
  arr: SpeechMark[],
  currentProgress: number,
  start = 0,
  end: number = arr.length - 1
): number | undefined {
  if (start > end) {
    return undefined;
  }

  const mid = Math.floor((start + end) / 2);

  const curr = arr[Number(mid)];
  const next = mid + 1 > arr.length - 1 ? undefined : arr[mid + 1];

  // In case the timestamp of the current item is below the current progress and
  // the next item is above, then we've found our active word!
  if (
    curr.time <= currentProgress &&
    currentProgress <= (next?.time ?? Infinity)
  ) {
    return mid;
  }

  // If the current progress is lower than the timestamp in the current item
  // then we need to traverse the lower half of the array!
  if (currentProgress < curr.time) {
    return getActiveWordIndex(arr, currentProgress, start, mid - 1);
  } else {
    // ... else search the higher half.
    return getActiveWordIndex(arr, currentProgress, mid + 1, end);
  }
}
