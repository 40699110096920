import { Speaker, ReadOutLoudArgs, AudioContext } from "../speaker";

/**
 * Loads, prepares and reads a range out loud.
 *
 * This function resolves as soon as reading begins.
 */
export function readOutLoud(args: ReadOutLoudArgs): AudioContext {
  return Speaker.readOutLoud(args);
}
