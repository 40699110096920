import { synthesizeSpeech } from "../methods";
import { PollyAudioContext } from "../utils";

import {
  SpeechMark,
  AudioContext,
  ReadOutLoudArgs,
  ReadOutLoudCallbacks,
} from "./typings";
import { getNewWordRange, isElementNode, stopAllAudioPlayers } from "./utils";
import { RangeChunk, splitRangeIntoChunks } from "./utils/splitRangeIntoChunks";

const PRELOAD_NEXT_CHUNK_WHEN_MS_LEFT = 30_000;

/**
 * Singleton that allows for speaking a given range out loud, inclucding karaoke.
 *
 * Only one instance of this should exists as it also functions as the single
 * source of truth of what is currently being read out loud on the host
 */
export class Speaker {
  private static currentId = 0;

  /**
   * contains the arguments given to the speaker, when the current session was
   * initialized
   */
  private static args?: ReadOutLoudArgs;

  /** Contains the currently active callbacks from the most recent Speaker call */
  private static callbacks?: ReadOutLoudCallbacks;

  /** References the audio-player used to read out loud */
  private static audioPlayer: PollyAudioContext;

  /**
   * If a reading-session is currently active, then this will contain karaoke-
   * metadata.
   */
  private static speechMarks?: SpeechMark[];

  /**
   * contains the list of chunks to be read out loud based on the given input
   * range (this can contain a mix of plain text and dynamically formatted
   * content such as LaTeX as well as potentially different language streams to
   * be read out loud seamlessly)
   */
  private static chunks?: RangeChunk[];
  private static currChunkIndex = 0;
  private static currChunkContentIndex = 0; // sub-index of the text chunk to read from within this range, used if a single range exceeds Pollys character limit
  private static isSynthesizingChunk = false;

  // progress tracking related fields
  private static progressTimeout?: number;
  private static prevProgress = 0;

  /** Fields related to karaoke */
  private static karaokeChunks?: {
    range: Range;
    startsAt: number;
    speechMarks?: SpeechMark[];
  }[];

  private static currKaraokeChunkIndex = 0;
  private static currKaraokeWordIndex = -1;
  private static prevKaraokeHighlightNode?: HTMLSpanElement;

  /**
   * Initializes the Speaker to read a new URL out loud and begins reading as
   * soon as data has been loaded.
   */
  public static readOutLoud(args: ReadOutLoudArgs): AudioContext {
    // Instantiate our audio-player the first time the user starts reading out loud.
    // (We assume that this is triggered by a touch/click event, since this will
    // allow the audioContext to enter and keep its 'running' state).
    if (!Speaker.audioPlayer) {
      Speaker.audioPlayer = new PollyAudioContext({ onEnd: Speaker.onEnd });
    }

    // if LaTeX is enabled, then auto-expand the range in case it starts/ends in
    // the middle of a MathJax/Katex-rendered formula to read the entire formula out
    // loud
    if (args.latexPrinter) {
      // @todo once mathjax has been fully obsoleted this code should be removed.
      extendRangeToStartOfMathJax(args.range);
      extendRangeToEndOfMathJax(args.range);

      extendRangeToStartOfKatex(args.range);
      extendRangeToEndOfKatex(args.range);
    }

    // Ensure previous readings are cancelled
    Speaker.reset(true);

    // now split the range into it's distinct chunks, so that we may read them
    // out loud one-by-one for a seamless integration of mixed content and
    // languages
    Speaker.args = args;
    Speaker.chunks = splitRangeIntoChunks(args.range, {
      voice: args.voice,
      latexPrinter: args.latexPrinter,
    });

    // bind callbacks as given from input arguments, but ensure that they will
    // trigger only for the current session - so e.g. if a new audio session is
    // started, then previously inserted callbacks will no longer trigger
    const id = ++Speaker.currentId;

    Speaker.callbacks = {
      onCancel: Speaker.onlyWhenMatchingId(id, args.onCancel),
      onEnd: Speaker.onlyWhenMatchingId(id, args.onEnd),
      onKaraokeHighlight: Speaker.onlyWhenMatchingId(
        id,
        args.onKaraokeHighlight
      ),
      onKaraokeNodeHide: Speaker.onlyWhenMatchingId(id, args.onKaraokeNodeHide),
      onProgress: Speaker.onlyWhenMatchingId(id, args.onProgress),
      onReady: Speaker.onlyWhenMatchingId(id, args.onReady),
      onStart: Speaker.onlyWhenMatchingId(id, args.onStart),
    };

    // create external audio context, to allow controlling the playback from
    // third party integrations
    const audioContext = {
      pause: Speaker.onlyWhenMatchingId(id, Speaker.pauseAudio),
      resume: Speaker.onlyWhenMatchingId(id, Speaker.resumeAudio),
      stop: Speaker.onlyWhenMatchingId(id, () => {
        Speaker.onEnd(true);
      }),
    };

    // if there were no chunks to be read out loud from the given input, then we
    // can safely assume we've reached the end right away
    if (Speaker.chunks?.length === 0) {
      args.onEnd?.();

      return audioContext;
    }

    // once we get here, everything has been setup - begin synthesizing speech
    // for the first available chunk, and then begin to read it out loud once
    // available
    Speaker.synthesizeSpeechForCurrentChunk().then(
      async () => {
        // In case the currently playing Speaker has changed since the request
        // began then bail out and simply trigger the ended callback
        if (id !== Speaker.currentId) {
          args.onEnd?.();

          return;
        }

        // Othwerwise once data has been loaded, then stop all audio elements
        // (including host audio elements) in preparation of starting our new
        // Speaker
        stopAllAudioPlayers();

        // trigger the onReady callback (if provided) and begin playback of the
        // audio
        if (Speaker.callbacks?.onReady) {
          await Speaker.callbacks.onReady(async () =>
            Speaker.audioPlayer.play()
          );
        } else {
          await Speaker.audioPlayer.play();
        }

        // begin tracking progress, so we can keep track of when to begin
        // loading the next available chunk
        Speaker.trackProgress();

        // ... and finally trigger the external onStart callback
        Speaker.callbacks?.onStart?.();

        // @todo - start karaoke
        // if (Speaker.args?.karaoke && Speaker.speechMarks?.length) {
        //   Speaker.karaoke();
        // }
      },
      (reason: unknown) => {
        args.onError?.(reason);
      }
    );

    // Return an audio-context external elements can use to keep track of the
    // audio-player. This context will only be valid as long as no new Speakings
    // are started.
    return audioContext;
  }

  /**
   * create speech synthesis for the current chunk, and return a promise that
   * resolves once it becomes available
   */
  private static async synthesizeSpeechForCurrentChunk() {
    const chunkIndex = Speaker.currChunkIndex;
    const chunk = Speaker.chunks?.[Speaker.currChunkIndex];
    const chunkContent = chunk?.content[Speaker.currChunkContentIndex];

    if (!chunk || !chunkContent) {
      return;
    }

    Speaker.isSynthesizingChunk = true;

    return synthesizeSpeech({
      text: chunkContent,
      includeSpeechMarks: Speaker.args?.karaoke && chunk.type === "text",
      speed: chunk.args?.speed ?? Speaker.args?.speed,
      voice: chunk.args?.voice ?? Speaker.args?.voice,
      cacheForMs: Speaker.args?.cacheForMs,
    }).then(async ({ mp3Url, speechMarks }) => {
      if (!Speaker.karaokeChunks) {
        Speaker.karaokeChunks = [];
      }

      // eslint-disable-next-line security/detect-object-injection
      const karaokeChunk = Speaker.karaokeChunks?.[chunkIndex];

      // if we've begun loading an entirely new range, then add it and it's
      // configuration to the karaoke chunk list
      // eslint-disable-next-line security/detect-object-injection
      if (!karaokeChunk) {
        Speaker.karaokeChunks.push({
          startsAt: Speaker.audioPlayer.duration,
          range: chunk.range.cloneRange(),
          speechMarks,
        });
      } else if (speechMarks && karaokeChunk.speechMarks) {
        // ... otherwise push the speechMarks for the newly loaded chunk to the
        // existing karaoke chunk
        karaokeChunk.speechMarks = [
          ...karaokeChunk.speechMarks,
          ...mapSpeechMarks(
            speechMarks,
            Speaker.audioPlayer.duration - karaokeChunk.startsAt
          ),
        ];
      }

      // and then load mp3Url and add it to the list of buffers available for
      // playback
      await Speaker.audioPlayer?.addUrl(mp3Url);

      // @todo - cache speechmarks!!!
      Speaker.isSynthesizingChunk = false;
    });
  }

  /**
   * setup progress tracking, by continuously checking how far we've pushed
   * ahead within an animation frame loop
   */
  private static trackProgress() {
    if (Speaker.progressTimeout) {
      // prevent binding to animation frame multiple times
      window.cancelAnimationFrame(Speaker.progressTimeout);
    }

    const onProgress = () => {
      if (!Speaker.chunks) {
        return;
      }

      // begin preloading the next chunk automatically, if we're closer to the
      // end of the current chunk than our defined threshold
      if (
        Speaker.audioPlayer.duration - Speaker.audioPlayer.currentTime <
        PRELOAD_NEXT_CHUNK_WHEN_MS_LEFT
      ) {
        Speaker.loadNextChunk();
      }

      // constantly trigger karaoke tick callback if reading out loud was
      // enabled, so we can keep the current highlight node up-to-date
      if (Speaker.args?.karaoke) {
        Speaker.onKaraokeTick();
      }

      // for the next trick, we need to try to extrapolate the estimated total
      // duration of the audio file - we do so, by assuming that all chunks will
      // represent a duration that's almost equivalent to the string length of
      // their content, and then extrapolating from there
      let totalTextInputLength = 0;
      let loadedTextInputLength = 0;
      let loadedChunkCount = 0;

      for (const chunk of Speaker.chunks) {
        for (const text of chunk.content) {
          loadedChunkCount++;
          totalTextInputLength += text.length;

          if (loadedChunkCount <= Speaker.audioPlayer.loadedBuffers) {
            loadedTextInputLength += text.length;
          }
        }
      }

      const estimatedTotalDuration =
        (Speaker.audioPlayer.duration / loadedTextInputLength) *
        totalTextInputLength;

      // determine the next progress value to emit (on a scale from 0 to 1),
      // based on the time elapsed divided by the estimated total duration
      //
      // note that we intentionally prevent progress from ever reverting
      // backwards, which can happen as we load more blocks and our estimates
      // become more precise in regards to the actual total duration
      const nextProgress = Math.max(
        Speaker.prevProgress,
        Speaker.audioPlayer.currentTime / estimatedTotalDuration
      );

      // trigger the external progress event
      Speaker.callbacks?.onProgress?.(nextProgress);

      // ... and track progress again next time
      Speaker.prevProgress = nextProgress;
      Speaker.progressTimeout = window.requestAnimationFrame(onProgress);
    };

    Speaker.progressTimeout = window.requestAnimationFrame(onProgress);
  }

  /**
   * internal utility to automatically begin loading the next chunk, this should
   * only ever be called from within the trackProgress callback loop once the
   * playback starts approaching the end of the current chunk
   */
  private static loadNextChunk() {
    if (Speaker.isSynthesizingChunk) {
      // prevent synthesizing more than one chunk at a time (this can happen, as
      // we trigger this method from within the trackProgress callback - meaning
      // that it'll trigger many times a second after we begin to approach the
      // end of a chunk)
      return;
    }

    const currChunk = Speaker.chunks?.[Speaker.currChunkIndex];

    if (currChunk?.content[Speaker.currChunkContentIndex + 1]) {
      // if there are more text within the current range chunk, then progress to
      // the next content chunk within the current range
      Speaker.currChunkContentIndex++;
    } else {
      // ... otherwise continue to the next actual range chunk, and begin
      // reading it from the start
      Speaker.currChunkIndex++;
      Speaker.currChunkContentIndex = 0;
    }

    Speaker.synthesizeSpeechForCurrentChunk();
  }

  /**
   * triggered from within the trackProgress loop, and enables checking to see
   * which node should currently be highlighted based on provided speech marks
   * and ranges
   */
  private static onKaraokeTick() {
    if (!Speaker.karaokeChunks) {
      return;
    }

    // determine which chunk we're currently reading out loud, by finding the
    // last instance that starts BEFORE the current time
    const karaokeChunkIndex = findLastIndex(
      Speaker.karaokeChunks,
      (it) => it.startsAt < Speaker.audioPlayer.currentTime
    );

    // eslint-disable-next-line security/detect-object-injection
    const karaokeChunk = Speaker.karaokeChunks[karaokeChunkIndex];

    if (!karaokeChunk) {
      // bail out if no matching chunk was found - in that case, there is simply
      // nothing we can do
      return;
    }

    // if chunk index has changed, then reset current highlight indexes so we
    // can start over within the next one
    if (Speaker.currKaraokeChunkIndex !== karaokeChunkIndex) {
      Speaker.currKaraokeChunkIndex = karaokeChunkIndex;
      Speaker.currKaraokeWordIndex = -1;

      if (!karaokeChunk.speechMarks) {
        // if no speech marks were provided for this chunk, then the only thing we
        // can do is to highlight the entire range for the duration while the
        // range is being read out loud
        Speaker.highlightKaraokeRange(karaokeChunk.range);
        return;
      }
    }

    if (!karaokeChunk.speechMarks) {
      return;
    }

    // ... otherwise, we will need to perform highlight of the specific word
    // that matches the content of our range
    const res = getNewWordRange({
      prevIndex: Speaker.currKaraokeWordIndex,
      progressInMs: Speaker.audioPlayer.currentTime - karaokeChunk.startsAt,
      range: karaokeChunk.range,
      speechMarks: karaokeChunk.speechMarks,
    });

    if (res) {
      Speaker.currKaraokeWordIndex = res.index;
      Speaker.highlightKaraokeRange(res.highlightNode);
    }
  }

  /**
   * adds highlight to a karaoke wrapper node by triggering the external
   * callback to allow it to run whatever transition is seen fit there
   */
  private static highlightKaraokeRange(
    rangeOrHighlightNode: Range | HTMLSpanElement
  ) {
    Speaker.removeKaraokeHighlightNode();

    let highlightNode =
      rangeOrHighlightNode instanceof HTMLElement
        ? rangeOrHighlightNode
        : undefined;

    const range =
      rangeOrHighlightNode instanceof HTMLElement
        ? undefined
        : rangeOrHighlightNode;

    if (!highlightNode && range) {
      // extract the content of the given range into a distinct DOM element, which
      // we can provide to the host to perform whatever highlight transition is
      // seen fit in third-party integrations
      highlightNode = document.createElement("span");
      highlightNode.appendChild(range.extractContents());

      range.insertNode(highlightNode);
    }

    // We should never encounter this code as the highlightNode will be set on a
    // range in case an explicit node hasn't been defined. As such this check
    // exists to keep TypeScript happy.
    if (!highlightNode) {
      console.warn(
        "Speaker.highlightKaraokeRange(): Failed to highlight range",
        highlightNode,
        range
      );
      return;
    }

    // trigger host callback to perform actual highlight
    Speaker.callbacks?.onKaraokeHighlight?.(highlightNode);
    Speaker.prevKaraokeHighlightNode = highlightNode;
  }

  /**
   * Removes a highlightNode from the DOM, optionally waiting for any host
   * callbacks to finish before actually removing the node.
   */
  private static async removeKaraokeHighlightNode() {
    const highlightNode = Speaker.prevKaraokeHighlightNode;

    if (!highlightNode) {
      return;
    }

    // reset internal state - soon there won't be any highlighted nodes left
    Speaker.prevKaraokeHighlightNode = undefined;

    // Allow the host to finish its onHide callback, running exit transitions
    // and similar before fully removing the node.
    await Speaker.callbacks?.onKaraokeNodeHide?.(highlightNode);

    // In order to preserve event listeners we create a range containing the
    // highlight node to remove and simply insert its content (i.e. NOT creating
    // new nodes, hence preserving listeners etc.) directly into the
    // dom next to the highlight node - which we can then safely remove
    // afterwards, as it is now empty
    const extractorRange = document.createRange();
    extractorRange.selectNodeContents(highlightNode);

    highlightNode.parentNode?.insertBefore(
      extractorRange.extractContents(),
      highlightNode
    );
    highlightNode.remove();
  }

  /**
   * Method returned to the host, allowing to resume paused speakers.
   */
  private static async resumeAudio() {
    if (!Speaker.audioPlayer.paused) {
      return;
    }

    await Speaker.audioPlayer.resume();

    if (Speaker.callbacks?.onProgress) {
      Speaker.trackProgress();
    }
  }

  /**
   * Method returned to the host, allowing pausing and playing of the Speaker
   */
  private static pauseAudio() {
    Speaker.removeKaraokeHighlightNode();

    if (Speaker.progressTimeout) {
      window.cancelAnimationFrame(Speaker.progressTimeout);
    }

    Speaker.audioPlayer.pause();
  }

  /**
   * Method returned to the host, completely stopping the speaker.
   *
   * NB: If this method is called, then calling resumeAudio() from the host
   * will not work.
   */
  private static onEnd(cancelled?: boolean) {
    // In case the playback was cancelled due to whatever reason, then reset
    // immediately.
    if (cancelled) {
      Speaker.reset(cancelled);

      return;
    }

    // Resets should not occur before all chunks have been played.. We append
    // 100ms seconds to the currentTime to allow for false positives, which may
    // be triggered if the JS rounding/impresicions causes currentTime to be less
    // than duration once we reach the end.
    if (
      Speaker.currChunkIndex < (Speaker.chunks?.length ?? 0) ||
      Speaker.audioPlayer.currentTime + 100 < Speaker.audioPlayer.duration
    ) {
      return;
    }

    Speaker.reset(cancelled);
  }

  private static reset(cancelled?: boolean) {
    if (Speaker.progressTimeout) {
      window.cancelAnimationFrame(Speaker.progressTimeout);
    }

    Speaker.removeKaraokeHighlightNode();

    Speaker.args = undefined;
    Speaker.chunks = undefined;
    Speaker.karaokeChunks = undefined;
    Speaker.currChunkIndex = 0;
    Speaker.currChunkContentIndex = 0;
    Speaker.isSynthesizingChunk = false;
    Speaker.prevProgress = 0;
    Speaker.currKaraokeChunkIndex = 0;
    Speaker.currKaraokeWordIndex = -1;
    Speaker.prevKaraokeHighlightNode = undefined;

    Speaker.audioPlayer.pause();
    Speaker.audioPlayer.reset();

    if (cancelled) {
      Speaker.callbacks?.onCancel?.();
    } else {
      Speaker.callbacks?.onEnd?.();
    }

    // remove speaker callbacks, so we no longer trigger any legacy callbacks
    Speaker.callbacks = undefined;
  }

  /**
   * Safetynet to ensure that callback functions from the host only can be executed
   * as long as the id passed when they were bound matches the current id of the Speaker.
   */
  private static onlyWhenMatchingId(id: number, cb: undefined): undefined;
  private static onlyWhenMatchingId<A extends unknown[], R>(
    id: number,
    cb: (...args: A) => R
  ): (...args: A) => R;
  private static onlyWhenMatchingId<A extends unknown[], R>(
    id: number,
    cb: ((...args: A) => R) | undefined
  ): ((...args: A) => R) | undefined;
  private static onlyWhenMatchingId<A extends unknown[], R>(
    id: number,
    cb: ((...args: A) => R) | undefined
  ): ((...args: A) => R) | undefined {
    if (!cb) {
      return;
    }

    return (...args: A): R => {
      if (id !== Speaker.currentId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: hack'ish, but we only trigger callback if matching ID - and
        // no one will care about void return values if this is no longer the
        // latest test
        return;
      }

      return cb(...args);
    };
  }
}

/**
 * Maps the time of speechmarks in a chunk to take other chunks into account
 */
function mapSpeechMarks(
  speechMarks: { time: number; value: string }[],
  currDuration: number
) {
  return speechMarks.map((speechMark) => ({
    time: speechMark.time + currDuration,
    value: speechMark.value,
  }));
}

/**
 * @todo once mathjax has been fully obsoleted this code should be removed.
 */
function isMathJaxNode(node: Node): node is Element {
  return !!(
    isElementNode(node) &&
    node.classList.contains("MathJax") &&
    node.nextElementSibling?.matches(`script[type="math/tex"]`)
  );
}

/**
 * utility to extend the start of a range to include the full formula, in case
 * it starts in the middle of a MathJax element
 *
 * @todo once mathjax has been fully obsoleted this code should be removed.
 */
function extendRangeToStartOfMathJax(range: Range) {
  let startContainer: Node | null = range.startContainer;

  while (startContainer) {
    if (!isMathJaxNode(startContainer)) {
      startContainer = startContainer.parentElement;
      continue;
    }

    range.setStartBefore(startContainer);
    return;
  }
}

/**
 * utility to extend the start of a range to include the full formula, in case
 * it starts in the middle of a MathJax element
 *
 * @todo once mathjax has been fully obsoleted this code should be removed.
 */
function extendRangeToEndOfMathJax(range: Range) {
  let endContainer: Node | null = range.endContainer;

  while (endContainer) {
    if (!isMathJaxNode(endContainer) || !endContainer.nextElementSibling) {
      endContainer = endContainer.parentElement;
      continue;
    }

    // push the end of the range forwards till after the following element
    // (which we know is the script[type="math/tex"] containing the LaTeX that
    // we need to properly read the formula out loud)
    range.setEndAfter(endContainer.nextElementSibling);
    return;
  }
}

function isKatexNode(node: Node): node is Element {
  return !!(
    isElementNode(node) &&
    node.classList.contains("katex") &&
    !!node.querySelector("annotation[encoding='application/x-tex']")
  );
}

/**
 * utility to extend the start of a range to include the full formula, in case
 * it starts in the middle of a MathJax element
 */
function extendRangeToStartOfKatex(range: Range) {
  let startContainer: Node | null = range.startContainer;

  while (startContainer) {
    if (!isKatexNode(startContainer)) {
      startContainer = startContainer.parentElement;
      continue;
    }

    range.setStartBefore(startContainer);
    return;
  }
}

/**
 * utility to extend the start of a range to include the full formula, in case
 * it starts in the middle of a MathJax element
 */
function extendRangeToEndOfKatex(range: Range) {
  let endContainer: Node | null = range.endContainer;

  while (endContainer) {
    if (!isKatexNode(endContainer) || !endContainer.nextElementSibling) {
      endContainer = endContainer.parentElement;
      continue;
    }

    range.setEndAfter(endContainer);
    return;
  }
}

function findLastIndex<T>(
  arr: T[],
  checker: (item: T, index: number) => boolean
): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    // eslint-disable-next-line security/detect-object-injection
    const item = arr[i];

    if (checker(item, i)) {
      return i;
    }
  }

  return -1;
}
