/**
 * unifies the creation of output fragments with a set of standardised
 * parameters to guarantee consistent output across handlers
 */
export function makeOutputFragment(
  content: string,
  { spaceBefore = true }: { spaceBefore?: boolean } = {}
): string {
  return spaceBefore ? ` ${content}` : content;
}
